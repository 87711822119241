import * as React from "react";
import { Helmet } from "react-helmet";
import "./global.css";

import * as styles from "./datenschutz.module.css";

const DatenschutzPage = () => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Datenschutzerklärung</title>
      </Helmet>
      <div className={styles.datenschutz}>
        <h1 style={{ lineBreak: 'anywhere' }}>Datenschutzerklärung</h1>
        <div className={styles.info}>
          <div>
            <h2 id="einleitung-ueberblick">Einleitung und Überblick</h2>
            <p>Wir haben diese Datenschutzerklärung (Fassung 27.03.2022-111981787) verfasst, um Ihnen gemäß der Vorgaben der <a className={styles.link} href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111981787" target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br />
              <strong>Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
            <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong>Begriffe leserfreundlich erklärt</strong>, <strong>Links</strong> zu weiterführenden Informationen geboten und <strong>Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
              Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
            <h2 id="anwendungsbereich">Anwendungsbereich</h2>
            <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
            <ul>
              <li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
              <li>Social Media Auftritte und E-Mail-Kommunikation</li>
              <li>mobile Apps für Smartphones und andere Geräte</li>
            </ul>
            <p>
              <strong>Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
            <h2 id="rechtsgrundlagen">Rechtsgrundlagen</h2>
            <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.<br />
              Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a className={styles.link} href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111981787" target="_blank" rel="noopener">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</a> nachlesen.</p>
            <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
            <ol>
              <li>
                <strong>Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
              <li>
                <strong>Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
              <li>
                <strong>Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
              <li>
                <strong>Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
            </ol>
            <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
            <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
            <ul>
              <li>In <strong>Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong>Datenschutzgesetz</strong>), kurz <strong>DSG</strong>.</li>
              <li>In <strong>Deutschland</strong> gilt das <strong>Bundesdatenschutzgesetz</strong>, kurz<strong> BDSG</strong>.</li>
            </ul>
            <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>
            <h2 id="speicherdauer">Speicherdauer</h2>
            <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
            <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
            <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
            <h2 id="rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</h2>
            <p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
            <ul>
              <li>Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
                <ul>
                  <li>zu welchem Zweck wir die Verarbeitung durchführen;</li>
                  <li>die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
                  <li>wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;</li>
                  <li>wie lange die Daten gespeichert werden;</li>
                  <li>das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
                  <li>dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);</li>
                  <li>die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
                  <li>ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
                </ul>
              </li>
              <li>Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
              <li>Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
              <li>Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
              <li>Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
              <li>Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
                <ul>
                  <li>Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
                  <li>Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
                  <li>Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
                </ul>
              </li>
              <li>Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
            </ul>
            <p>
              <strong>Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
            <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a className={styles.link} href="https://www.dsb.gv.at/?tid=111981787" target="_blank" rel="noopener">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a className={styles.link} href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noopener">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:</p>
            <h2 id="oesterreich-datenschutzbehoerde">Österreich Datenschutzbehörde</h2>
            <p>
              <strong>Leiterin: </strong>Mag. Dr. Andrea Jelinek<strong>
                <br />
                Adresse: </strong>Barichgasse 40-42, 1030 Wien<strong>
                <br />
                Telefonnr.: </strong>+43 1 52 152-0<strong>
                <br />
                E-Mail-Adresse: </strong>
              <a className={styles.link} href="mailto:dsb@dsb.gv.at" target="_blank" rel="noopener">dsb@dsb.gv.at</a>
              <strong>
                <br />
                Website: </strong>
              <a className={styles.link} href="https://www.dsb.gv.at/" target="_blank" rel="noopener">https://www.dsb.gv.at/</a>
            </p>
            <h2 id="sicherheit-datenverarbeitung">Sicherheit der Datenverarbeitung</h2>
            <p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen schließen können.</p>
            <p>Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.</p>
            <h2 id="tls-verschluesselung-https">TLS-Verschlüsselung mit https</h2>
            <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.<br />
              Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist &#8211; niemand kann &#8220;mithören&#8221;.</p>
            <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch Technikgestaltung <a className={styles.link} href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=111981787" target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.<br />
              Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18" /> links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
              Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach &#8220;Hypertext Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu erhalten.</p>
            <h2 id="kommunikation">Kommunikation</h2>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Kommunikation Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br />
                    &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br />
                    &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br />
                    &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                </tr>
              </tbody>
            </table>
            <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
            <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben solange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
            <h3>Betroffene Personen</h3>
            <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
            <h3>Telefon</h3>
            <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
            <h3>E-Mail</h3>
            <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
            <h3>Online Formulare</h3>
            <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
            <h3>Rechtsgrundlagen</h3>
            <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
            <ul>
              <li>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
              <li>Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
              <li>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
            </ul>
            <h2 id="cookies">Cookies</h2>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Cookies Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
                    &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
                    &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren variieren<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
                </tr>
              </tbody>
            </table>
            <h3>Was sind Cookies?</h3>
            <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br />
              Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</p>
            <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
            <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
            <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
            <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>
            <p>
              <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg" alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%" />
            </p>
            <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>
            <p>So können zum Beispiel Cookie-Daten aussehen:</p>
            <p style={{ lineBreak: 'anywhere' }}>
              <strong>Name:</strong> _ga<br />
              <strong>Wert:</strong> GA1.2.1326744211.152111981787-9<br />
              <strong>Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br />
              <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
            <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
            <ul>
              <li>Mindestens 4096 Bytes pro Cookie</li>
              <li>Mindestens 50 Cookies pro Domain</li>
              <li>Mindestens 3000 Cookies insgesamt</li>
            </ul>
            <h3>Welche Arten von Cookies gibt es?</h3>
            <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
            <p>Man kann 4 Arten von Cookies unterscheiden:</p>
            <p>
              <strong>Unerlässliche Cookies<br />
              </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
            <p>
              <strong>Zweckmäßige Cookies<br />
              </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</p>
            <p>
              <strong>Zielorientierte Cookies<br />
              </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
            <p>
              <strong>Werbe-Cookies<br />
              </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
            <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
            <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a className={styles.link} href="https://datatracker.ietf.org/doc/html/rfc6265">https://datatracker.ietf.org/doc/html/rfc6265</a>, dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management Mechanism&#8221;.</p>
            <h3>Zweck der Verarbeitung über Cookies</h3>
            <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.</p>
            <h3>Welche Daten werden verarbeitet?</h3>
            <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.</p>
            <h3>Speicherdauer von Cookies</h3>
            <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
            <p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.</p>
            <h3>Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
            <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
            <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
            <p>
              <a className={styles.link} href="https://support.google.com/chrome/answer/95647?tid=111981787" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
            </p>
            <p>
              <a className={styles.link} href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111981787" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
            </p>
            <p>
              <a className={styles.link} href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111981787" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
            </p>
            <p>
              <a className={styles.link} href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=111981787">Internet Explorer: Löschen und Verwalten von Cookies</a>
            </p>
            <p>
              <a className={styles.link} href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=111981787">Microsoft Edge: Löschen und Verwalten von Cookies</a>
            </p>
            <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome Browsers.</p>
            <h3>Rechtsgrundlage</h3>
            <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
            <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt. bestehen <strong>berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
            <p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
            <p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte Software Cookies verwendet.</p>
            <h2 id="webhosting-einleitung">Webhosting Einleitung</h2>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Webhosting Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />
                    &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br />
                    &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
                </tr>
              </tbody>
            </table>
            <h3>Was ist Webhosting?</h3>
            <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
            <p>Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari.</p>
            <p>Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites.</p>
            <p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>
            <p>Zur Veranschaulichung:</p>
            <p>
              <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
            </p>
            <h3>Warum verarbeiten wir personenbezogene Daten?</h3>
            <p>Die Zwecke der Datenverarbeitung sind:</p>
            <ol>
              <li>Professionelles Hosting der Website und Absicherung des Betriebs</li>
              <li>zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
              <li>Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</li>
            </ol>
            <h3>Welche Daten werden verarbeitet?</h3>
            <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
            <ul>
              <li>die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
              <li>Browser und Browserversion (z. B. Chrome 87)</li>
              <li>das verwendete Betriebssystem (z. B. Windows 10)</li>
              <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. <a className={styles.link} href="https://www.beispielquellsite.de/vondabinichgekommen.html/" target="_blank" rel="follow noopener">https://www.beispielquellsite.de/vondabinichgekommen.html/</a>)</li>
              <li>den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
              <li>Datum und Uhrzeit</li>
              <li>in Dateien, den sogenannten Webserver-Logfiles</li>
            </ul>
            <h3>Wie lange werden Daten gespeichert?</h3>
            <p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
            <p>
              <strong>Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>
            <h3>Rechtsgrundlage</h3>
            <p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
            <p>Zwischen uns und dem Hostingprovider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>
            <h2 id="messenger-kommunikation-einleitung">Messenger &amp; Kommunikation Einleitung</h2>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Messenger &amp; Kommunikation Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Kontaktanfragen und die allgemeine Kommunikation zwischen uns und Ihnen<br />
                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Name, Adresse, E-Mailadresse, Telefonnummer, allgemeine Inhaltsdaten, gegebenenfalls IP-Adresse<br />
                    Mehr Details dazu finden Sie bei den jeweils eingesetzten Tools.<br />
                    &#x1f4c5; Speicherdauer: abhängig von den verwendeten Messenger- &amp; Kommunikationsfunktionen<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 S. 1 lit. b. DSGVO (vertragliche oder vorvertragliche Verpflichtungen)</td>
                </tr>
              </tbody>
            </table>
            <h3>Was sind Messenger- &amp; Kommunikationsfunktionen?</h3>
            <p>Wir bieten auf unserer Website verschiedene Möglichkeiten (etwa Messenger- und Chatfunktionen, Online- bzw. Kontaktformulare, E-Mail, Telefon), um mit uns zu kommunizieren. Dabei werden auch Ihre Daten, soweit es für die Beantwortung Ihrer Anfrage und unseren darauffolgenden Maßnahmen nötig ist, verarbeitet und gespeichert.</p>
            <p>Wir nutzen neben klassischen Kommunikationsmitteln wie E-Mail, Kontaktformularen oder Telefon auch Chats bzw. Messenger. Die derzeit am häufigsten verwendete Messenger-Funktion ist WhatsApp, aber es gibt natürlich speziell für Websites viele verschiedene Anbieter, die Messenger-Funktionen anbieten. Wenn Inhalte Ende zu Ende verschlüsselt sind, wird darauf in den einzelnen Datenschutztexten oder in der Datenschutzerklärung des jeweiligen Anbieters hingewiesen. Eine Ende-zu-Ende-Verschlüsselung bedeutet nichts anders, als dass Inhalte einer Nachricht selbst für den Anbieter nicht sichtbar sind. Allerdings können trotzdem Informationen zu Ihrem Gerät, Standorteinstellungen und andere technische Daten verarbeitet und gespeichert werden.</p>
            <h3>Warum nutzen wir Messenger- &amp; Kommunikationsfunktionen?</h3>
            <p>Kommunikationsmöglichkeiten mit Ihnen sind für uns von großer Bedeutung. Schließlich wollen wir mit Ihnen sprechen und alle möglichen Fragen zu unserem Service bestmöglich beantworten. Eine gut funktionierende Kommunikation ist bei uns ein wichtiger Teil unserer Dienstleistung. Mit den praktischen Messenger- &amp; Kommunikationsfunktionen können Sie jederzeit jene wählen, die Ihnen am liebsten sind. In Ausnahmefällen kann es aber auch vorkommen, dass wir bestimmte Fragen über Chat bzw. Messenger nicht beantworten. Das ist der Fall, wenn es etwa um interne vertragliche Angelegenheiten geht. Hier empfehlen wir andere Kommunikationsmöglichkeiten wie E-Mail oder Telefon.</p>
            <p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europäische Gerichtshof hat jedoch entschieden, dass in bestimmten Fällen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist weiter unten bei der betroffenen Plattform wiedergegeben.</p>
            <p>Bitte beachten Sie, dass bei der Nutzung unserer eingebauten Elemente auch Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden können, da viele Anbieter, beispielsweise Facebook-Messenger oder WhatsApp amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
            <h3>Welche Daten werden verarbeitet?</h3>
            <p>Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen Anbieter der Messenger- &amp; Kommunikationsfunktionen ab. Grundsätzlich handelt es sich um Daten wie etwa Name, Adresse, Telefonnummer, E-Mailadresse und Inhaltsdaten wie beispielsweise alle Informationen, die Sie in ein Kontaktformular eingeben. Meistens werden auch Informationen zu Ihrem Gerät und die IP-Adresse gespeichert. Daten, die über eine Messenger- &amp; Kommunikationsfunktion erhoben werden, werden auch auf den Servern der Anbieter gespeichert.</p>
            <p>Wenn Sie genau wissen wollen, welche Daten bei den jeweiligen Anbietern gespeichert und verarbeitet werden und wie Sie der Datenverarbeitung widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des Unternehmens sorgfältig durchlesen.</p>
            <h3>Wie lange werden Daten gespeichert?</h3>
            <p>Wie lange die Daten verarbeitet und gespeichert werden, hängt in erster Linie von unseren verwendeten Tools ab. Weiter unten erfahren Sie mehr über die Datenverarbeitung der einzelnen Tools. In den Datenschutzerklärungen der Anbieter steht üblicherweise genau, welche Daten wie lange gespeichert und verarbeitet werden. Grundsätzlich werden personenbezogene Daten nur so lange verarbeitet, wie es für die Bereitstellung unserer Dienste nötig ist. Wenn Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Die Daten können gleich nach dem Verlassen einer Website wieder gelöscht werden, sie können aber auch über mehrere Jahre gespeichert bleiben. Daher sollten Sie sich jedes einzelnen Cookie im Detail ansehen, wenn Sie über die Datenspeicherung Genaueres wissen wollen. Meistens finden Sie in den Datenschutzerklärungen der einzelnen Anbieter auch aufschlussreiche Informationen über die einzelnen Cookies.</p>
            <h3>Widerspruchsrecht</h3>
            <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Für weitere Informationen verweisen wir auf den Abschnitt zur Einwilligung.</p>
            <p>Da bei Messenger- &amp; Kommunikationsfunktionen Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
            <h3>Rechtsgrundlage</h3>
            <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Messenger- &amp; Kommunikationsfunktionen verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Wir bearbeiten Ihre Anfrage und verwalten Ihre Daten im Rahmen vertraglicher oder vorvertraglicher Beziehungen, um unsere vorvertraglichen und vertraglichen Pflichten zu erfüllen bzw. Anfragen zu beantworten. Grundlage dafür ist <strong>Art. 6 Abs. 1 S. 1 lit. b. DSGVO</strong>. Grundsätzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet.</p>
            <h2 id="content-delivery-networks-einleitung">Content Delivery Networks Einleitung</h2>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Content Delivery Networks Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Optimierung unserer Serviceleistung (um die Website schneller laden zu können)<br />
                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Ihre IP-Adresse<br />
                    Mehr Details dazu finden Sie weiter unten und den einzelnen Datenschutztexten.<br />
                    &#x1f4c5; Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erfüllung der Dienstleistung nicht mehr benötigt werden<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                </tr>
              </tbody>
            </table>
            <h3>Was ist ein Content Delivery Network?</h3>
            <p>Wir nutzen auf unserer Website ein sogenanntes Content Delivery Network. Meistens wird ein solchen Netzwerk nur CDN genannt. Ein CDN hilft uns, unsere Website, unabhängig von Ihrem Standort, schnell und problemlos zu laden. Dabei werden auch personenbezogene Daten von Ihnen auf den Servern des eingesetzten CDN-Anbieters gespeichert, verwaltet und verarbeitet. Nachfolgend gehen wir allgemein näher auf den Dienst und dessen Datenverarbeitung ein. Genaue Informationen über den Umgang mit Ihren Daten finden Sie in der jeweiligen Datenschutzerklärung des Anbieters.</p>
            <p>Jedes Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die alle über das Internet miteinander verbunden sind. Über dieses Netzwerk können Inhalte von Websites (speziell auch sehr große Dateien) auch bei großen Lastspitzen schnell und reibungslos ausgeliefert werden. Das CDN legt dafür eine Kopie unserer Website auf Ihren Servern an. Da diese Server weltweit verteilt sind, kann die Website schnell ausgeliefert werden. Die Datenübertragung zu Ihrem Browser wird folglich durch das CDN deutlich verkürzt.</p>
            <h3>Warum verwenden wir ein Content Delivery Network für unsere Website?</h3>
            <p>Eine schnell ladende Website ist Teil unserer Dienstleistung. Wir wissen natürlich, wie nervig es ist, wenn eine Website im Schneckentempo lädt. Meist verliert man sogar die Geduld und sucht das Weite, bevor die Website vollständig geladen ist. Das wollen wir natürlich vermeiden. Daher gehört eine schnell ladende Website ganz selbstverständlich zu unserem Websiteangebot. Mit einem Content Delivery Network wird in Ihrem Browser unsere Website deutlich schneller geladen. Besonders hilfreich ist der Einsatz des CDNs wenn Sie im Ausland sind, weil die Website von einem Server in Ihrer Nähe ausgeliefert wird.</p>
            <h3>Welche Daten werden verarbeitet?</h3>
            <p>Wenn Sie eine Website bzw. die Inhalte einer Website anfordern und diese in einem CDN zwischengespeichert sind, leitet das CDN die Anforderung an den von Ihnen am nächsten gelegenen Server und dieser liefert die Inhalte aus. Content Delivery Networks sind so aufgebaut, dass JavaScript-Bibliotheken heruntergeladen werden können und auf npm- und Github-Servern gehostet werden. Alternativ können bei den meisten CDNs auch WordPress-Plugins geladen werden, wenn diese auf <a className={styles.link} href="https://wordpress.org/" target="_blank" rel="noopener">WordPress.org</a> gehostet werden. Ihr Browser kann personenbezogene Daten an das von uns eingesetzte Content Delivery Network senden. Dabei handelt es sich etwa um Daten wie IP-Adresse, Browsertyp, Browserversion, welche Webseite geladen wird oder Uhrzeit und Datum des Seitenbesuchs. Diese Daten werden vom CDN gesammelt und auch gespeichert. Ob zur Datenspeicherung Cookies verwendet werden, hängt von dem eingesetzten Network ab. Bitte lesen Sie sich dafür die Datenschutztexte des jeweiligen Dienstes durch.</p>
            <h3>Widerspruchsrecht</h3>
            <p>Falls Sie diese Datenübertragung vollkommen unterbinden wollen, können Sie einen JavaScript-Blocker (siehe beispielsweise <a className={styles.link} href="https://noscript.net/" target="_blank" rel="noopener">https://noscript.net/</a>) auf Ihrem PC installieren. Natürlich kann dann unsere Website nicht mehr das gewohnte Service (wie beispielsweise eine schnelle Ladegeschwindigkeit) bieten.</p>
            <h3>Rechtsgrundlage</h3>
            <p>Wenn Sie eingewilligt haben, dass ein Content Delivery Network eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch ein Content Delivery Network vorkommen kann, dar.</p>
            <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, ein Content Delivery Network zu verwenden, um unser Online-Service zu optimieren und sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das Tool gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
            <p>Informationen zu speziellen Content Delivery Network erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
            <h2 id="sonstiges-einleitung">Sonstiges Einleitung</h2>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Sonstiges Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Verbesserung der Nutzererfahrung<br />
                    &#x1f4d3; Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den verwendeten Diensten ab. Meist handelt es sich um IP-Adresse und/oder technische Daten. Mehr Details dazu finden Sie bei den jeweils eingesetzten Tools.<br />
                    &#x1f4c5; Speicherdauer: abhängig von den eingesetzten Tools<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                </tr>
              </tbody>
            </table>
            <h3>Was fällt unter „Sonstiges“?</h3>
            <p>Unter die Kategorie „Sonstiges“ fallen jene Dienste, die nicht in eine der oben genannten Kategorien passen. Dabei handelt es sich in der Regel um diverse Plugins und eingebundene Elemente, die unsere Website verbessern. In der Regel werden diese Funktionen von Drittanbietern bezogen und in unsere Website eingebunden. Beispielsweise handelt es sich dabei um Websuch-Dienste wie Algolia Place, Giphy, Programmable Search Engine oder Onlinedienste für Wetterdaten wie etwa OpenWeather.</p>
            <h3>Warum verwenden wir weitere Drittanbieter?</h3>
            <p>Wir wollen Ihnen mit unserer Website das beste Webangebot in unserer Branche bieten. Schon lange ist eine Website nicht bloß eine reine Visitenkarte für Unternehmen. Vielmehr ist es ein Ort, der Ihnen behilflich sein soll, zu finden wonach Sie suchen. Um stets unsere Website für Sie noch interessanter und hilfreicher zu machen, nutzen wir diverse Dienste von Drittanbietern.</p>
            <h3>Welche Daten werden verarbeitet?</h3>
            <p>Immer wenn Elemente in unsere Website eingebunden werden, wird Ihre IP-Adresse an den jeweiligen Anbieter übermittelt, gespeichert und dort verarbeitet werden. Das ist nötig, weil sonst die Inhalte nicht an Ihren Browser gesendet werden und folglich nicht entsprechend dargestellt werden. Es kann auch vorkommen, dass Dienstanbieter auch Pixel-Tags bzw. Web-Beacons verwenden. Das sind kleine Grafiken auf Websites, die eine Logdatei aufzeichnen und auch Analysen dieser Datei erstellen können. Mit den erhaltenen Informationen können die Anbieter ihre eigenen Marketingmaßnahmen verbessern. Neben Pixel-Tags können solche Informationen (wie beispielsweise welchen Button Sie klicken oder wann Sie welche Seite aufrufen) auch in Cookies gespeichert werden. Darin können neben Analysedaten zu Ihrem Webverhalten auch technische Informationen wie etwa Ihr Browsertyp oder Ihr Betriebssystem gespeichert werden. Manche Anbieter können die gewonnenen Daten auch mit anderen internen Diensten oder auch mit Drittanbieter verknüpfen. Jeder Anbieter pflegt einen anderen Umgang mit Ihren Daten. Daher empfehlen wir Ihnen sorgfältig die Datenschutzerklärungen der jeweiligen Dienste durchzulesen. Wir sind grundsätzlich bemüht, nur Dienste zu verwenden, die mit dem Thema Datenschutz sehr vorsichtig umgehen.</p>
            <h3>Dauer der Datenverarbeitung</h3>
            <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist.</p>
            <h3>Rechtsgrundlage</h3>
            <p>Wenn wir Sie um Ihre Einwilligung bitte und Sie auch einwilligen, dass wir den Dienst verwenden dürfen, gilt dies als Rechtsgrundlage der Verarbeitung Ihrer Daten (Art. 6 Abs. 1 lit. a DSGVO).  Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Die Rechtsgrundlage dafür ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
            <p>Informationen zu den speziellen Tools, erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
            <h2 id="google-maps-datenschutzerklaerung">Google Maps Datenschutzerklärung</h2>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Google Maps Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa eingegebene Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten.<br />
                    Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                    &#x1f4c5; Speicherdauer: abhängig von den gespeicherten Daten<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
                </tr>
              </tbody>
            </table>
            <h3>Was ist Google Maps?</h3>
            <p>Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google Maps können wir Ihnen Standorte besser zeigen und damit unser Service an Ihre Bedürfnisse anpassen. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert. Hier wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden können.</p>
            <p>Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC, ein Tablet oder eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts mittels HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. Satellitenbild. Dank der Street View Bilder und den hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.</p>
            <h3>Warum verwenden wir Google Maps auf unserer Website?</h3>
            <p>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer Webseite zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen Standorten liefern. Sie sehen auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres Kundenservice.</p>
            <h3>Welche Daten werden von Google Maps gespeichert?</h3>
            <p>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite eingebunden haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.</p>
            <p>Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:</p>
            <p>
              <strong>Name:</strong> NID<br />
              <strong>Wert:</strong> 188=h26c1Ktha7fCQTx8rXgLyATyITJ111981805-5<br />
              <strong>Verwendungszweck:</strong> NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre persönlichen Einstellungen für Werbezwecke zu sammeln.<br />
              <strong>Ablaufdatum:</strong> nach 6 Monaten</p>
            <p>
              <strong>Anmerkung:</strong> Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen nie auszuschließen. Um das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden war.</p>
            <h3>Wie lange und wo werden die Daten gespeichert?</h3>
            <p>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a className={styles.link} href="https://www.google.com/about/datacenters/locations/?hl=de">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
            </p>
            <p>Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server lahm legt, bleiben die Daten ziemlich sicher trotzdem geschützt.</p>
            <p>Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel Werbedaten) in Serverprotokollen, indem es einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten löscht.</p>
            <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
            <p>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur Standortbestimmung und Web-/App-Aktivität &#8211; abhängig von Ihrer Entscheidung &#8211; entweder 3 oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- oder ausschalten.</p>
            <p>In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies immer etwas anders. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
            <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
            <h3>Rechtsgrundlage</h3>
            <p>Wenn Sie eingewilligt haben, dass Google Maps eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google Maps vorkommen kann, dar.</p>
            <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Maps zu verwenden, um unser Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Maps gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
            <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
            <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className={styles.link} href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
            </p>
            <p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche den Standardvertragsklauseln entsprechen, finden Sie unter <a className={styles.link} href="https://business.safety.google/adsprocessorterms/" target="_blank" rel="noopener">https://business.safety.google/adsprocessorterms/</a>.</p>
            <p>Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des Unternehmens unter <a className={styles.link} href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
            <p>Alle Texte sind urheberrechtlich geschützt.</p>
            <p style={{ marginTop: '15px' }}>Quelle: Erstellt mit dem <a className={styles.link} href="https://www.adsimple.at/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Österreich">Datenschutz Generator</a> von AdSimple</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatenschutzPage;
